import { Card, Col, Container, Row } from "react-bootstrap";
import { BsFillHouseFill, BsWifi } from "react-icons/bs";
import { BiSolidBed, BiSolidBath } from "react-icons/bi";
import { FaCouch } from "react-icons/fa";

// https://www.vrbo.com/en-ca/cottage-rental/p515047vb?

export default function About() {
  return (
    <>
      <Container style={{ minHeight: "600px" }}>
        <Row xs={1} md={2}>
          <Col className="mb-4">
            <div className="d-flex flex-column" style={{ gap: "2rem" }}>
              <div className="d-flex align-items-center">
                <BsFillHouseFill style={{ margin: "0.5rem" }} size={32} />
                <div>
                  <h5 style={{ margin: 0 }}>
                    <b>Condo</b>
                  </h5>
                  1081 sq. ft
                </div>
              </div>
              <div className="d-flex align-items-center">
                <BiSolidBed style={{ margin: "0.5rem" }} size={32} />
                <div>
                  <h5 style={{ margin: 0 }}>
                    <b>2 Bedrooms</b>
                  </h5>
                  1 Queen and 1 Single / 2 Doubles
                </div>
              </div>
              <div className="d-flex align-items-center">
                <BiSolidBath style={{ margin: "0.5rem" }} size={32} />
                <div>
                  <h5 style={{ margin: 0 }}>
                    <b>2 Bathrooms</b>
                  </h5>
                  1 Shower / 1 Tub
                </div>
              </div>
              <div className="d-flex align-items-center">
                <FaCouch style={{ margin: "0.5rem" }} size={32} />
                <div>
                  <h5 style={{ margin: 0 }}>
                    <b>Living Spaces</b>
                  </h5>
                  Living Room / Kitchen / Dining Area
                </div>
              </div>
              <div className="d-flex align-items-center">
                <BsWifi style={{ margin: "0.5rem" }} size={32} />
                <div>
                  <h5 style={{ margin: 0 }}>
                    <b>WIFI Included</b>
                  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <Card
              style={{
                borderStyle: "none",
                boxShadow: "0px 0px 16px 0px rgba(17, 17, 26, 0.08)",
              }}
            >
              <div style={{ margin: "1rem", maxWidth: "50rem" }}>
                <p>
                  Gated community with large heated pool , water park, exercise
                  room, ping pong, pool tables, video games, 40 seat theatre, 2
                  playgrounds, tennis, volleyball, basketball, and miniature
                  golfing on site and guest services to help with local
                  attractions. 2018 new slides with no additional fee like other
                  resorts. <br />
                  <br /> 2 Bedrooms, 2 Bathrooms - 1 Queen, 1 Single, 2 Full
                  <br />
                  <br /> All linens, bath towels and pool towels are provided.{" "}
                  <br />
                  <br /> This condo is on the top floor and is quiet for
                  sleeping. No one above to disturb your sleep for your busy
                  days. Elevator on site. Lots of parking available for your
                  vehicles. <br />
                  <br /> Great value vacation condo sleeps up to 6. This condo
                  includes a fully equipped kitchen with all appliances and
                  utensils. There is a full size washer and dryer, central air
                  and heat, iron and ironing board, telephone service, patio
                  table and chairs. TV's in all rooms. Less than a minute walk
                  to the pool and clubhouse.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
