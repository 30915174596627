import { Button, Card, Container } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { useState } from "react";
import backupReviews from "../backup_reviews.json";

export interface Summary {
  reviewCount: number;
  averageRating: number;
}
export interface Review {
  key: string;
  name: string;
  title: string;
  message: string;
  rating: number;
  date: string;
  response: {
    title: string;
    message: string;
  };
}

const getReviews = (start: number, end: number) => {
  let reviews = [];
  for (let i = start; i < end; i++) {
    reviews.push(backupReviews[i]);
  }
  return reviews.map((r) => ({
    key: r.id,
    name: r.reviewAuthorAttribution?.text as string,
    title: r.title,
    message: r.text,
    rating:
      Number(
        r.reviewScoreWithDescription.value.substring(
          0,
          r.reviewScoreWithDescription.value.indexOf("/")
        )
      ) / 2,
    date: r.submissionTime.longDateFormat,
    response: {
      title: r.managementResponses[0]?.header.text.replace(
        "VrboOwner",
        "owner"
      ),
      message: r.managementResponses[0]?.response,
    },
  })) as Review[];
};

export default function Reviews() {
  const [reviews, setReviews] = useState<Review[]>(getReviews(0, 5));

  const summary = {
    count: backupReviews.length,
    rating: 4.8,
  };

  return (
    <>
      <Container>
        <h1 style={{ margin: 0 }}>{summary.count} Reviews</h1>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Rating
              initialValue={summary.rating as number}
              allowFraction={true}
              size={45}
              readonly
            />
          </div>
          <span style={{ fontSize: "1.5rem" }}>
            &#40;
            {(summary.rating as number).toFixed(1)}
            &#41;
          </span>
        </div>
        {reviews.map((review) => (
          <Card
            key={review.key}
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              borderStyle: "none",
              boxShadow: "0px 0px 16px 0px rgba(17, 17, 26, 0.08)",
            }}
          >
            <Card.Body>
              <Card.Title>
                <h4>
                  <b>{review.title || "Untitled"}</b>
                </h4>
                <Card.Subtitle>
                  <span style={{ fontSize: "0.85rem" }}>by {review.name}</span>
                </Card.Subtitle>
                <div>
                  <Rating
                    initialValue={review.rating}
                    allowFraction={true}
                    size={25}
                    readonly
                  />
                </div>
              </Card.Title>
              <Card.Text
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  textIndent: "0",
                  textAlign: "left",
                }}
              >
                {review.message}
              </Card.Text>
              <span style={{ fontSize: "0.85rem" }}>{review.date}</span>
              {review.response.title ? (
                <>
                  <hr />
                  <h4>
                    <b>{review.response.title}</b>
                  </h4>
                  {review.response.message}
                </>
              ) : null}
            </Card.Body>
          </Card>
        ))}
        <div style={{ textAlign: "center" }}>
          <Button
            hidden={reviews.length === summary.count}
            onClick={() => {
              setReviews((prev) => [
                ...prev,
                ...getReviews(
                  reviews.length,
                  Math.min(reviews.length + 5, summary.count)
                ),
              ]);
            }}
          >
            Show More
          </Button>
        </div>
      </Container>
    </>
  );
}
